.undefined-subregion {
  fill: #f4f4f4;
}

.undefined-subregion,
.other-subregion,
.adriatic-subregion,
.continental-subregion,
.baleric-subregion,
.north-african-subregion {
  stroke: #969696;
  stroke-width: 0.25px;
}

.undefined-subregion,
.other-subregion,
.adriatic-subregion,
.continental-subregion,
.baleric-subregion,
.north-african-subregion,
.port-circle {
  stroke-miterlimit: 10;
}

.other-subregion {
  fill: #6c7d92;
}

.adriatic-subregion {
  fill: #66a8cc;
}

.continental-subregion {
  fill: #a7bdd3;
}

.baleric-subregion {
  fill: #84b6d8;
}

.north-african-subregion {
  fill: #3b91b7;
}

.port-center {
  fill: #1a314e;
}

.port-circle {
  fill: none;
  stroke: #1a314e;
  stroke-width: 0.1px;
}

.port-name {
  font-size: 1.1px;
}

.port-name,
.region-name {
  font-family:
    CenturyGothic-Bold,
    Century Gothic;
  font-weight: 700;
}

.port {
  cursor: pointer;
}

.port.selected [class*='port-name'] {
  fill: red;
}
.port.selected [class*='region'] {
  opacity: 0.7;
}

.port.red > path {
  fill: rgb(245, 150, 155);
}

.port.grey > path {
  fill: rgb(195, 195, 195);
}

.port.blue > path {
  fill: rgb(150, 215, 235);
}

.region-name {
  font-size: 3px;
}

.port:hover [class*='region'] {
  opacity: 0.7;
}
.map-legend-container {
  position: absolute;
  margin-top: 40px;
}
.map-legend {
  display: flex;
  align-items: center;
}
.map-legend > span {
  font-weight: 500;
}
.map-legend-box {
  width: 14px;
  height: 14px;
  border: solid 1px black;
  margin: 5px;
}
.map-legend-box.blue {
  background-color: rgb(150, 215, 235);
}
.map-legend-box.red {
  background-color: rgb(245, 150, 155);
}
