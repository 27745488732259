.undefined-subregion {
  fill: #f4f4f4;
}

.undefined-subregion,
.other-subregion,
.adriatic-subregion,
.continental-subregion,
.baleric-subregion,
.north-african-subregion {
  stroke: #969696;
  stroke-width: 0.25px;
}

.undefined-subregion,
.other-subregion,
.adriatic-subregion,
.continental-subregion,
.baleric-subregion,
.north-african-subregion,
.port-circle {
  stroke-miterlimit: 10;
}

.other-subregion {
  fill: #6c7d92;
}

.adriatic-subregion {
  fill: #66a8cc;
}

.continental-subregion {
  fill: #a7bdd3;
}

.baleric-subregion {
  fill: #84b6d8;
}

.north-african-subregion {
  fill: #3b91b7;
}

.port-center {
  fill: #1a314e;
}

.port-circle {
  fill: none;
  stroke: #1a314e;
  stroke-width: 0.1px;
}

.port-name {
  font-size: 1.1px;
}

.port-name,
.region-name {
  font-family:
    CenturyGothic-Bold,
    Century Gothic;
  font-weight: 700;
}

.region-name {
  font-size: 3px;
}

.region {
  cursor: pointer;
}

.region.selected [class*='region-name'] {
  fill: red;
}

.region.selected [class*='region'] {
  opacity: 0.8;
}

.region:hover [class*='region'] {
  opacity: 0.7;
}
